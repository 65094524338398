/* eslint-disable eslint-comments/disable-enable-pair */
import getConfig from 'next/config';

import GTMEvent from '../enums/gtm-event';
import {isServerSide} from '../utils/env';
import {getPerUnitOrMultiplePriceString} from '../helpers/product-card-helpers';
import {IStoreProduct} from '../autogen/IStoreProduct';
import {IProduct} from '../autogen/IProduct';

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
      dataLayer: any[];
      parentIFrame: any;
    }
  }

const pushToDataLayer = (event) => {
    const windowDataLayer = global.dataLayer;

    if (windowDataLayer) {
        windowDataLayer.push(event);
    }
};

export const reportCartClick = () =>
    pushToDataLayer({
        event: 'cartClick',
        gaEventAction: 'cartClick',
        gaEventCategory: 'Ecommerce',
        gaEventLabel: `Navigating to cart from ${window.location.pathname}`
    });

export const reportPromotionEvent = (props, select?: boolean) => {
    pushToDataLayer({
        ecommerce: {
            items: [
                {
                    coupon: props.dealId,
                    index: 0,
                    item_id: props.deal.adId,
                    item_name: props.deal.dealTitle,
                    price: props.price,
                    product_position: props.position
                }
            ],
            promotion_name: props.dealId
        },
        event: select ? 'select_promotion' : 'view_promotion'
    });
};

export const addToCartDataLayer = (product: IProduct, storeProduct: IStoreProduct, quantity: number) => {
    if (!product || !storeProduct) {
        return;
    }

    const price = getPerUnitOrMultiplePriceString({
        ...product,
        ...storeProduct
    });

    pushToDataLayer({
        ecommerce: {
            add: {
                products: [{
                    brand: '',
                    category: '',
                    id: product.productId,
                    name: product.name,
                    price,
                    quantity
                }]
            }
        },
        event: 'addToCart',
        gaEventAction: `${window.location.pathname}`,
        gaEventCategory: 'Ecommerce',
        gaEventLabel: `Adding to cart from ${window.location.pathname}`
    });
};

export const removeFromCartDataLayer = (product, quantity) => {
    if (!product) {
        return;
    }

    pushToDataLayer({
        ecommerce: {
            remove: {
                products: [{
                    brand: '',
                    category: '',
                    id: product.productId,
                    name: product.name,
                    price: product.price,
                    quantity
                }]
            }
        },
        event: 'removeFromCart',
        gaEventAction: `${window.location.pathname}`,
        gaEventCategory: 'Ecommerce',
        gaEventLabel: `Removing from cart from ${window.location.pathname}`
    });
};

export const dataLayerCheck = () =>
    Boolean(window.dataLayer) || Boolean(window.parentIFrame && window.parent && window.parent.window.dataLayer);

export const waitForDataLayer = (callback) => {
    if (isServerSide()) {
        return;
    }

    if (dataLayerCheck()) {
        callback();

        return;
    }

    const interval = setInterval(() => {
        if (dataLayerCheck()) {
            clearInterval(interval);

            // eslint-disable-next-line node/callback-return
            callback();
        }
    }, 200);
};

export const sharedEvolyticsFields = () => ({
    environment: getConfig().publicRuntimeConfig.globalNavEnvironment,
    page_type: 'ecommerce',
    promo_percent_viewed: '1',
    site_name: 'Hy-Vee',
    site_subcategory: 'deals'
});

export const reportEvolyticsClick = (ad, customerContext) => {
    if (ad) {
        waitForDataLayer(() => {
            window.dataLayer.push({
                account_login_status: `logged ${customerContext.isAuthenticated ? 'in' : 'out'}`,
                card_id: customerContext.customerCardId?.toString() ?? '',
                event: GTMEvent.EvolyticsClick,
                promo_action: 'clicked',
                promo_id: ad.adId,
                promo_name: ad.title,
                ...sharedEvolyticsFields
            });
        });
    }
};

export const reportEvolyticsImpression = (ad, customerContext) => {
    if (ad) {
        waitForDataLayer(() => {
            window.dataLayer.push({
                account_login_status: `logged ${customerContext.isAuthenticated ? 'in' : 'out'}`,
                card_id: customerContext.customerCardId?.toString() ?? '',
                event: GTMEvent.EvolyticsImpression,
                promo_action: 'viewed',
                promo_id: ad.adId,
                promo_name: ad.title,
                ...sharedEvolyticsFields
            });
        });
    }
};

export const reportAdEvolyticsImpression = (ad, customerContext, eventName) => {
    if (ad) {
        waitForDataLayer(() => {
            window.dataLayer.push({
                account_login_status: `logged ${customerContext.isAuthenticated ? 'in' : 'out'}`,
                ad_resolution: ad.adResolution || undefined,
                alt_text: ad.altText || undefined,
                card_id: customerContext.fuelSaverNumber?.toString() || undefined,
                customer_id: customerContext.customerId || undefined,
                element_position_on_page: Math.round(ad.elementPositionOnPage || 0) || undefined,
                event: eventName,
                page_length: Math.round(ad.pageLength || 0) || undefined,
                promo_action: 'viewed',
                promo_category: ad.title || undefined,
                promo_destination: ad.destination || undefined,
                promo_id: ad.adId || undefined,
                promo_name: ad.title || undefined,
                uuid: customerContext.customerUuid || undefined,
                ...sharedEvolyticsFields()
            });
        });
    }
};
