import {ApolloClient, FetchResult} from '@apollo/client';
import {MutationActions, mutateNavigationData} from '@hy-vee/global-navigation';

import {graphqlClient} from '../../graphql-client';
import {ICartItem} from '../../../autogen/ICartItem';
import {updateCartCacheAfterCreate, updateCartCacheAfterDelete} from '../mutation-helpers/cart-mutation-helpers';
import {
    createCartItemMutation,
    deleteCartItemMutation,
    updateCartItemMutation
} from '../../../../src/graphql/mutations/cart-mutations';
import {DeleteCartItemMutationVariables} from '../../../autogen/DeleteCartItemMutation';

export const updateCartItemAction = async (updateCartItemInput): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> => {
    const client = graphqlClient() as ApolloClient<any>;
    const changeQuantityAction = MutationActions.changeCartItemQuantity(updateCartItemInput.cartItemId.toString(), updateCartItemInput.quantity);

    await mutateNavigationData(changeQuantityAction);

    return client.mutate({
        errorPolicy: 'all',
        mutation: updateCartItemMutation,
        variables: {
            cartItemInput: updateCartItemInput
        }
    });
};

// eslint-disable-next-line require-await
export const createCartItemAction = async (createCartItemInput, customer, setLoading) => {
    const client = graphqlClient();

    return client.mutate({
        mutation: createCartItemMutation,
        update: (cache, data) => updateCartCacheAfterCreate(cache, data.data.createCartItem, customer, setLoading),
        variables: {
            createCartItemInput,
            customerId: customer.customerId
        }
    });
};

export const deleteCartItemAction = async ({cartItemId}: {cartItemId: string; cartId: number}, customerId = 0) => {
    const client = graphqlClient() as ApolloClient<any>;
    const deleteItemAction = MutationActions.removeCartItem(cartItemId);

    await mutateNavigationData(deleteItemAction);

    return client.mutate<ICartItem, DeleteCartItemMutationVariables>({
        errorPolicy: 'all',
        mutation: deleteCartItemMutation,
        update: (cache) => updateCartCacheAfterDelete(cache, cartItemId),
        variables: {
            cartItemId: Number(cartItemId),
            customerId
        }
    });
};
