import {getCartItemQuantity} from '@hy-vee/shared-utils-aisles-online';

import {ICartItem} from '../autogen/ICartItem';
import {GetAislesOnlineCart_carts, GetAislesOnlineCart_carts_cartItems} from '../autogen/GetAislesOnlineCart';

export interface ICartItemExtended {
    cartItemId: ICartItem['cartItemId'];
    quantity: ICartItem['quantity'];
    quantityType?: ICartItem['quantityType'];
    storeProduct?: ICartItem['storeProduct'];
}

export const getNumberOfItems = (cartItems: ICartItemExtended[]): number =>
    cartItems.reduce(
        (accumulator, cartItem) =>
            accumulator +
            getCartItemQuantity(
                cartItem.quantity,
                cartItem.quantityType,
                cartItem.storeProduct?.product?.averageWeight || null
            ),
        0
    );

export const getCartItemFromCart = (
    activeCart: GetAislesOnlineCart_carts | null,
    productId: number | string
): GetAislesOnlineCart_carts_cartItems | null => {
    const cartItems = activeCart?.cartItems ?? [];
    const cartItemForProduct = cartItems.find((cartItem) => Number(cartItem.storeProduct.product.productId) === Number(productId));

    return cartItemForProduct || null;
};

export const parseCartId = (cartData: GetAislesOnlineCart_carts): number | null =>
    cartData ? Number.parseInt(cartData.cartId) : null;
