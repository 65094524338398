import BigNumber from 'bignumber.js';
import {SubstitutionPreference} from '@hy-vee/shared-utils-aisles-online';

import {ICartItem} from '../autogen/ICartItem';
import {EACH, POUND} from '../enums/aisles-online-quantity-types';
import {IStoreProduct} from '../autogen/IStoreProduct';
import {IProduct} from '../autogen/IProduct';
import {IItemFragment} from '../autogen/IItemFragment';
import {IRetailItemFragment} from '../autogen/IRetailItemFragment';
import {createCartItemMutation} from '../../src/graphql/mutations/cart-mutations';
import {addToCartDataLayer, removeFromCartDataLayer} from '../services/analytics-service';
import {updateCartCacheAfterCreate} from '../graphql/mutations/mutation-helpers/cart-mutation-helpers';
import {
    createCartItemAction,
    deleteCartItemAction,
    updateCartItemAction
} from '../graphql/mutations/mutation-actions/cart-mutation-actions';
import {info, flush} from '../services/logging-service';

export const onChangeAmountInCartWhammy = (
    cartItem,
    product,
    storeProduct,
    customer,
    quantity,
    setLoading
) => {
    if (!cartItem) {
        const createCartItemInput = {
            productId: Number(product.productId),
            quantity,
            quantityType: storeProduct.isWeighted && !product.averageWeight ? POUND : EACH,
            storeProductId: Number(storeProduct.storeProductId),
            substitutionPreference: SubstitutionPreference.BEST_MATCH
        };

        return createCartItemAction(createCartItemInput, customer, setLoading);
    }

    const quantityDelta = Math.abs(quantity - cartItem.quantity);

    if (cartItem.quantity > quantity) {
        removeFromCartDataLayer(product, quantityDelta);
    }

    if (quantity === 0) {
        setLoading(false);

        return deleteCartItemAction(cartItem, customer.customerId);
    }

    setLoading(false);

    return updateCartItemAction({
        cartItemId: Number(cartItem.cartItemId),
        quantity
    });
};

export const onChangeAmountInCart = (
    createCartItemCallback: (object) => Promise<any>,
    cartItem: ICartItem,
    product: IProduct,
    storeProduct: IStoreProduct,
    customer: {customerId: number}
) => (quantity: number): Promise<any> => {
    if (!cartItem) {
        addToCartDataLayer(product, storeProduct, quantity);

        return createCartItemCallback({
            mutation: createCartItemMutation,
            update: (cache, data) => updateCartCacheAfterCreate(cache, data.data.createCartItem, customer),
            variables: {
                createCartItemInput: {
                    productId: Number(product.productId),
                    quantity,
                    quantityType: storeProduct.isWeighted && !product.averageWeight ? POUND : EACH,
                    storeProductId: Number(storeProduct.storeProductId),
                    substitutionPreference: SubstitutionPreference.BEST_MATCH
                },
                customerId: customer.customerId
            }
        });
    }

    const quantityDelta = Math.abs(quantity - cartItem.quantity);

    if (cartItem.quantity < quantity) {
        addToCartDataLayer(product, storeProduct, quantityDelta);
    } else {
        removeFromCartDataLayer(product, quantityDelta);
    }

    if (quantity === 0) {
        return deleteCartItemAction(cartItem);
    }

    return updateCartItemAction({
        cartItemId: Number(cartItem.cartItemId),
        quantity
    });
};

export const displayPrice = (price: number | undefined) => price !== undefined ? `$${new BigNumber(price).toFixed(2)}` : '';

interface IPricable {
    averageWeight: number | null
    priceMultiple: number
    price: number
    productId: number
    isWeighted: boolean
}

export const getPerUnitOrMultiplePriceString = (product: IPricable) => {
    if (product.priceMultiple > 1) {
        return `${product.priceMultiple} / ${displayPrice(product.price)}`;
    }

    if (product.isWeighted && !product.averageWeight) {
        return `${displayPrice(product.price)}/lb`;
    }

    return product.isWeighted && product.averageWeight
        ? `$${new BigNumber(product.price).times(product.averageWeight).toFixed(2)}`
        : displayPrice(product.price);
};

interface IPriceable {
    basePrice?: number,
    isWeighted: boolean;
    perksPrice: number | null;
    price: number;
    priceMultiple: number;
}

export const getItemPriceable = (retailItem: IRetailItemFragment): IPriceable => ({
    basePrice: retailItem.basePrice,
    isWeighted: Boolean(retailItem.soldByUnitOfMeasure && retailItem.soldByUnitOfMeasure.code !== 'CT'),
    perksPrice: retailItem.memberTagPrice,
    price: retailItem.ecommerceTagPrice,
    priceMultiple: retailItem.ecommerceTagPriceQuantity
});

interface IProductCardProductData {
    averageWeight: number | null;
    name: string;
    productImages: any;
    size: string;
}

export const getItemAvgWeight = (product: IProduct & {item: IItemFragment}): IProductCardProductData => ({
    averageWeight: product.item.unitAverageWeight,
    name: product.name,
    productImages: product.productImages,
    size: product.size
});

export const logMissingRetailItem = (pathName: string, storeProduct: IStoreProduct & {product: IProduct & {item: IItemFragment}} | null) => {
    if (storeProduct?.product && !storeProduct.product.item?.retailItems?.[0]) {
        const storeProductInfo = {
            productId: storeProduct.product.productId,
            storeId: storeProduct.storeId,
            storeProductId: storeProduct.storeProductId
        };

        info(pathName, 'Item API missing store product', storeProductInfo);

        flush();
    }
};
