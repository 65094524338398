export const ProductImpressions = 'productImpressions';

// eslint-disable-next-line no-shadow
enum GTMEvent {
    EvolyticsClick = 'select_promotion',
    EvolyticsImpression = 'view_promotion',
    AdPageLoad = 'ad_page_load'
}

export default GTMEvent;
