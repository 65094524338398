import {useEffect, useState} from 'react';

interface IWindowSizeResponse {
    height: number | undefined;
    width: number| undefined;
    isTabletWidth: boolean;
    isMobileWidth: boolean;
    isDesktopWidth: boolean;
}
export const mobileWidth = 768;
export const tabletWidth = 1024;

const useWindowSize = (): IWindowSizeResponse => {
    const [windowSize, setWindowSize] = useState<IWindowSizeResponse>({
        height: undefined,
        isDesktopWidth: true,
        isMobileWidth: false,
        isTabletWidth: false,
        width: undefined
    });

    useEffect(() => {
        const handleResize = () => {
            const isMobileWidth = window.innerWidth <= mobileWidth;
            const isTabletWidth = mobileWidth < window.innerWidth && window.innerWidth <= tabletWidth;

            setWindowSize({
                height: window.innerHeight,
                isDesktopWidth: !isMobileWidth && !isTabletWidth,
                isMobileWidth,
                isTabletWidth,
                width: window.innerWidth
            });
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

export default useWindowSize;
